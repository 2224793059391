<template>
  <div id="logo"></div>
</template>

<style>
[data-theme="dark"] #logo {
  background-image: url("/emblem-white.svg");
}
[data-theme="light"] #logo {
  background-image: url("/emblem-black.svg");
}

#logo {
  min-width: 30px;
  min-height: 50px;
  background-repeat: no-repeat;
  background-position: center;
}
</style>
